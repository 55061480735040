import StartView from "./view";
import KeyDownController from "./controller/keydown";
import Gamepad, { Event } from "core/gamepad";
import ButtonDownController from "./controller/buttondown";
export default function StartLevelScreen(element) {
  const view = StartView(element);
  view.onKeyDown(KeyDownController(view));
  //Gamepad.on(Event.ButtonDown, ButtonDownController(view));

  async function show() {
    return await view.execute();
  }
  return Object.assign(view, {
    show
  });
}