export default function Player(playerData) {
  const player = {
    lastPosition: {
      x: 0,
      y: 0
    },
    position: {
      x: 0,
      y: 0
    },
    velocity: {
      x: 0,
      y: 0
    },
    size: {
      width: 72,
      height: 97
    },
    opacity: 255,
    items: {},
    landed: false,
    ducking: false,
    addItem,
    reset,
    toData
  };
  function reset() {
    Object.assign(player.position, playerData.position);
    Object.assign(player.velocity, playerData.velocity);
    player.items = {};
    player.landed = false;
    player.ducking = false;
    player.opacity = 255;
  }
  function addItem(name) {
    if (!(name in player.items)) player.items[name] = 0;
    player.items[name]++;
  }
  function toData() {
    const {
      position,
      velocity
    } = player;
    return {
      position,
      velocity
    };
  }
  return player;
}