function WebStorage(key) {
  function get() {
    if (key in window.localStorage) return JSON.parse(window.localStorage[key]);
    return null;
  }
  const set = value => window.localStorage[key] = JSON.stringify(value);
  return {
    get,
    set
  };
}
export default WebStorage;