import Clock from "./clock";
import ItemsDisplay from "./items-display";
import Component, { Align } from "core/gui/component";
export default function HUD(level) {
  const {
    player
  } = level;
  const component = Component({
    name: "hud",
    alignment: {
      x: Align.Left,
      y: Align.Top
    },
    fillScene: true
  });
  const clock = Clock(level);
  const items = ItemsDisplay(player);
  component.add(clock);
  component.add(items);
  return component;
}