export default function Element(selector, parent = document) {
  const element = selector instanceof HTMLElement ? selector : parent.querySelector(selector);
  function setVisible(visible) {
    if (visible) element.classList.remove("hidden");else element.classList.add("hidden");
  }
  function setSelected(selected) {
    if (selected) element.classList.add("selected");else element.classList.remove("selected");
  }
  return Object.assign(element, {
    setVisible,
    setSelected
  });
}