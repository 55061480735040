import R from "core/resources";
import Component, { Align } from "core/gui/component";
import { makeTimeString } from "core/util";
const textSize = 64;
const padding = 10;
const width = 120;
const height = textSize + 1.5 * padding;
export default function Clock(level) {
  const font = R.fonts.sunnySpells;
  const component = Component({
    name: "clock",
    alignment: {
      x: Align.Center,
      y: Align.Top
    },
    size: {
      width,
      height
    }
  });
  component.renderComponent = p => {
    const millis = new Date() - level.timeStarted;
    const timeString = makeTimeString(millis);
    renderTime(p, timeString);
  };
  function renderTime(p, timeString) {
    const {
      width
    } = component.size;
    p.noStroke();
    p.fill(255);
    p.textFont(font);
    p.textSize(64);
    p.textAlign(p.CENTER, p.TOP);
    p.text(timeString, width / 2, padding);
  }
  return component;
}