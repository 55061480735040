import R from "core/resources";
import ToolBar, { Direction } from "core/gui/toolbar";
import Component, { Align } from "core/gui/component";
import LevelBox from "./levelbox";
import TileTool from "./tiletool";
import Renderer from "./renderer";
export default function Editor(scene, level, onExit = () => {}) {
  const renderer = Renderer(level);
  const component = Component({
    name: "editor",
    alignment: {
      x: Align.Left,
      y: Align.Top
    },
    fillScene: true
  });
  const tileTool = TileTool(scene, level, onExit);
  const levelBox = LevelBox(level);
  const toolBar = ToolBar([{
    sprite: R.gfx.tiles.getSprite("box.png").sprite,
    label: "Tiles",
    action: () => toolClicked("tiles")
  }, {
    sprite: R.gfx.items.getSprite("star.png").sprite,
    label: "Items",
    action: () => toolClicked("items")
  }], Direction.Column);
  function toolClicked(type) {
    tileTool.openSpriteChooser({
      type
    });
  }
  component.add(levelBox);
  component.add(toolBar);
  function onKeyPressed(p, evt) {
    switch (evt.keyCode) {
      case 27:
        // Escape
        onExit(level.toData());
        break;
    }
  }
  function setEnabled(enabled) {
    if (enabled) {
      scene.input.onKeyPressed(onKeyPressed);
      scene.gui.add(component);
      scene.render.on(renderer);
    } else {
      scene.input.offKeyPressed(onKeyPressed);
      scene.gui.remove(component);
      scene.render.off(renderer);
    }
    tileTool.setEnabled(enabled);
    if (enabled) {
      level.offset.x = 0;
      level.offset.y = 0;
    }
  }
  return {
    setEnabled
  };
}