import GameView from "./view";
import Level from "core/level";
import Main from "./main";
export default function GameScreen(element) {
  const view = GameView(element);
  async function show(levelData) {
    const level = Level(levelData);
    const main = Main(level, view);
    return await main.execute();
  }
  return Object.assign(view, {
    show
  });
}