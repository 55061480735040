import Deferred from "core/deferred";
import Events from "core/events";
export default function View({
  element,
  events = []
}) {
  events = events.constructor == Object ? Object.keys(events) : events;
  const eventsHandler = Events(events);
  let deferred = null;
  function resolve(data) {
    if (deferred == null) throw new Error("View not executed, deferred promise missing!");
    deferred.resolve(data);
  }
  async function execute() {
    deferred = new Deferred();
    return deferred.promise;
  }
  return Object.assign(element, {
    resolve,
    execute,
    events: eventsHandler
  });
}