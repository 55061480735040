import R from "core/resources";
import Log from "core/log";
import ScreenManager from "core/screen-manager";
const log = Log("main");
log.info("Feedback Game client up.");
R.init().then(() => {
  log.info("Preload done. App starting ...");
  ScreenManager();
});
document.body.oncontextmenu = evt => {
  return false;
};