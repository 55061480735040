import Timing from "core/timing";
export default function Physics(level) {
  const plugins = [];
  let enabled = true;
  const physics = {
    update,
    addPlugin,
    setEnabled
  };
  function update(p) {
    if (!enabled) return;
    const timer = Timing.start("physics");
    plugins.forEach(plugin => plugin(level, physics));
    timer.stop();
  }
  function addPlugin(plugin) {
    plugins.push(plugin);
  }
  function setEnabled(enab) {
    enabled = enab;
  }
  return physics;
}