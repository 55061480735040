export default function GridRenderer(grid, showParams = false) {
  const {
    rows,
    columns,
    getTile,
    gridToLevel,
    getItem,
    tileSize
  } = grid;
  return (p, scene) => {
    p.clear();
    p.fill(0);
    p.noStroke();
    p.textAlign(p.CENTER, p.CENTER);
    for (let row = 0; row < rows; row++) {
      for (let col = 0; col < columns; col++) {
        const tile = getTile(col, row);
        const tilePos = gridToLevel({
          x: col,
          y: row
        });
        if (tile) {
          const {
            sprite
          } = tile;
          p.image(sprite, tilePos.x, tilePos.y);
          if (showParams) renderTileParams(p, tile, tilePos, tileSize);
        }
        const item = getItem(col, row);
        if (item) {
          const {
            sprite
          } = item;
          p.image(sprite, tilePos.x, tilePos.y);
        }
      }
    }
  };
}
function renderTileParams(p, tile, tilePos, tileSize) {
  const {
    params
  } = tile;
  if (params?.blocking) {
    p.noFill();
    p.strokeWeight(1);
    p.stroke([255, 0, 0, 64]);
    p.line(tilePos.x, tilePos.y, tilePos.x + tileSize, tilePos.y + tileSize);
    p.line(tilePos.x, tilePos.y + tileSize, tilePos.x + tileSize, tilePos.y);
  }
  if (params?.walkable) {
    p.noStroke();
    p.fill([255, 255, 0, 64]);
    p.rect(tilePos.x, tilePos.y, tileSize, 20);
  }
}