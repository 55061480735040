import { post } from "core/api";
export default function AddController(view) {
  return async () => {
    console.log("add");
    const name = promptForName();
    if (name == null) return;
    try {
      const result = await post("/levels", {
        name
      });
      if (!result.success) {
        alert(result.details);
        return;
      }
      const {
        payload: {
          id
        }
      } = result;
      view.resolve(id);
    } catch (err) {
      console.error(err);
    }
  };
}
function promptForName() {
  let name = prompt("Name des Levels");
  if (name == null) return null;
  name = name.trim();
  if (!name) return null;
  return name;
}