const Questions = [{
  text: "Ich bin gerne hier.",
  answers: ["Ja", "Nein"]
}, {
  text: "Ich habe viel Spaß hier.",
  answers: ["Ja", "Nein"]
}, {
  text: "Ich weiß, wo ich mir hier Hilfe holen kann.",
  answers: ["Ja", "Nein"]
}, {
  text: "Alle Erwachsenen waren heute nett zu mir.",
  answers: ["Ja", "Nein"]
}, {
  text: "Ich musste nichts machen, was ich nicht wollte.",
  answers: ["Stimmt", "Stimmt nicht"]
}];
export default Questions;