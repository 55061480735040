const gamepads = {};
const Event = {
  Connect: "connect",
  Disconnect: "disconnect",
  Move: "move",
  ButtonDown: "buttonDown",
  ButtonUp: "buttonUp"
};
const handlers = Object.values(Event).reduce((obj, cur) => {
  obj[cur] = new Set();
  return obj;
}, {});
const Button = {
  RightBottom: 0,
  RightRight: 1,
  RightLeft: 2,
  RightTop: 3,
  FrontTopLeft: 4,
  FrontTopRight: 5,
  FrontBottomLeft: 6,
  FrontBottomRight: 7,
  CenterLeft: 8,
  CenterRight: 9,
  StickLeft: 10,
  StickRight: 11,
  LeftTop: 12,
  LeftBottom: 13,
  LeftLeft: 14,
  LeftRight: 15,
  CenterCenter: 16
};
const Axis = {
  LeftHorizontal: 0,
  LeftVertical: 1,
  RightHorizontal: 2,
  RightVertical: 3
};
window.addEventListener("gamepadconnected", evt => {
  addGamepad(evt.gamepad);
});
window.addEventListener("gamepaddisconnected", evt => {
  removeGamepad(evt.gamepad);
});
function addGamepad(gamepad) {
  gamepads[gamepad.index] = {
    lastValues: null,
    gamepad
  };
  dispatch(Event.Connect, gamepad);
}
function removeGamepad(gamepad) {
  delete gamepads[gamepad.id];
  dispatch(Event.Disconnect, gamepad);
}
function updateGamepads() {
  const gps = navigator.getGamepads();
  for (const gamepad of gps) {
    if (gamepad) {
      // Can be null if disconnected during the session
      if (gamepad.index in gamepads) {
        gamepads[gamepad.index].gamepad = gamepad;
      } else {
        addGamepad(gamepad);
      }
      checkChanges(gamepad.index);
    }
  }
}
function on(event, callback) {
  if (!(event in handlers)) throw `Unknown event! ${event}`;
  handlers[event].add(callback);
}
function off(event, callback) {
  if (!(event in handlers)) throw `Unknown event! ${event}`;
  handlers[event].delete(callback);
}
function dispatch(event, ...data) {
  if (!(event in handlers)) throw `Unknown event! ${event}`;
  Array.from(handlers[event]).forEach(callback => {
    callback(...data);
  });
}
function checkChanges(gpIndex) {
  const entry = gamepads[gpIndex];
  const {
    lastValues,
    gamepad
  } = entry;
  const {
    axes,
    buttons
  } = gamepad;
  if (lastValues) {
    axes.forEach((value, index) => {
      if (value != lastValues.axes[index]) dispatch(Event.Move, {
        gamepad,
        index,
        value
      });
    });
    const lastDown = buttons.map((btn, index) => ({
      button: btn,
      index
    })).filter(({
      _,
      index
    }) => lastValues.buttons[index].pressed);
    const lastUp = buttons.map((btn, index) => ({
      button: btn,
      index
    })).filter(({
      _,
      index
    }) => !lastValues.buttons[index].pressed);
    lastDown.forEach(checkButton);
    lastUp.forEach(checkButton);
    function checkButton({
      button: btn,
      index
    }) {
      const {
        pressed,
        touched,
        value
      } = btn;
      const last = lastValues.buttons[index];
      if (last.pressed != pressed || last.touched != touched || last.value != value) {
        const event = pressed || touched || value > 0 ? Event.ButtonDown : Event.ButtonUp;
        dispatch(event, {
          gamepad,
          index,
          pressed,
          touched,
          value
        });
      }
    }
  } else {
    entry.lastValues = {};
  }

  // update last values
  {
    const {
      lastValues
    } = entry;
    lastValues.axes = axes.slice();
    lastValues.buttons = buttons.map(({
      pressed,
      touched,
      value
    }) => ({
      pressed,
      touched,
      value
    }));
  }
}
setInterval(updateGamepads, 50);
const Gamepad = {
  all: () => Object.values(gamepads).map(({
    gamepad
  }) => gamepad),
  on,
  off
};
export default Gamepad;
export function PressedButtons() {
  const pressed = new Set();
  Gamepad.on(Event.ButtonDown, ({
    index
  }) => {
    pressed.add(index);
  });
  Gamepad.on(Event.ButtonUp, ({
    index
  }) => {
    pressed.delete(index);
  });
  return pressed;
}
export function Axes() {
  const axes = {};
  Gamepad.on(Event.Move, ({
    index,
    value
  }) => {
    axes[index] = value;
  });
  return axes;
}
export { Axis, Button, Event };