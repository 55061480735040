import { PressedButtons, Axes } from "../gamepad";
import Events from "core/events";
export const EVENTS_TO_RELAY = ["setup", "draw", "windowResized", "keyPressed", "keyReleased", "mousePressed", "mouseReleased", "mouseClicked", "mouseMoved", "mouseDragged", "mouseWheel", "mouseOut"];
export const GLOBAL_EVENTS = ["windowResized", "setup", "draw"];
export default function Input(id) {
  const events = Events(EVENTS_TO_RELAY);
  const input = Object.assign(events, {
    init
  });
  const pressedKeys = PressedKeys(input);
  input.isKeyPressed = key => pressedKeys.has(key);
  const pressedButtons = PressedButtons();
  input.isButtonPressed = btn => pressedButtons.has(btn);
  const axes = Axes();
  input.getAxis = index => axes[index];
  input.getAxes = () => axes;
  function init(p) {
    pressedKeys.clear();
    pressedButtons.clear();
    EVENTS_TO_RELAY.forEach(eventName => {
      p[eventName] = evt => {
        if (GLOBAL_EVENTS.includes(eventName) || id == evt.target.id) {
          //evt && evt.preventDefault();
          events.fire(eventName, p, evt);
        }
      };
    });
  }
  return input;
}
export function PressedKeys(input) {
  let pressed = new Set();
  input.onKeyPressed((p, evt) => {
    const ctrlPressed = evt.getModifierState("Control");
    if (ctrlPressed) return;
    pressed.add(evt.keyCode);
  });
  input.onKeyReleased((p, evt) => {
    pressed.delete(evt.keyCode);
  });
  return pressed;
}