export default function FollowCamera(scene, level) {
  const {
    player,
    offset
  } = level;
  const {
    position
  } = player;
  return p => {
    offset.x = position.x - p.width / 2;
    offset.y = position.y - p.height / 2;
  };
}