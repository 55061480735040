import GridRenderer from "./grid-renderer";
import PlayerRenderer from "game/player/renderer";
export default function LevelRenderer(level) {
  const {
    grid,
    player,
    levelToScreen
  } = level;
  const gridRenderer = GridRenderer(grid);
  const playerRenderer = PlayerRenderer(player);
  const render = (p, scene) => {
    p.push();
    const pos = levelToScreen({
      x: 0,
      y: 0
    });
    p.translate(pos.x, pos.y);
    gridRenderer(p, scene);
    playerRenderer(p, scene);
    p.pop();
  };
  return Object.assign(render, {
    setPlayerVariant: playerRenderer.setPlayerVariant
  });
}