import Events from "../events";
export const Align = {
  None: "none",
  Top: "top",
  Left: "left",
  Bottom: "bottom",
  Right: "right",
  Center: "center"
};
const EventsArray = ["mousePressed", "mouseMoved", "componentIn", "componentOut"];
let nameId = 0;
export default function Component(config = {}) {
  const children = new Set();
  const events = Events(EventsArray);
  const component = Object.assign(events, {
    parent: null,
    name: `Unnamed${nameId++}`,
    position: {
      x: 0,
      y: 0
    },
    size: {
      width: 48,
      height: 32
    },
    alignment: {
      x: Align.None,
      y: Align.None
    },
    fillScene: false,
    enabled: true,
    add,
    remove,
    render,
    renderComponent,
    renderChildren,
    componentAt,
    handleEvent
  }, config);
  function add(child) {
    child.parent = component;
    children.add(child);
  }
  function remove(child) {
    children.delete(child);
    child.parent = null;
  }
  function render(p) {
    if (!component.enabled) return;
    if (component.fillScene) {
      component.size.width = p.width;
      component.size.height = p.height;
    }
    calculatePosition(p);
    const {
      position
    } = component;
    const {
      x,
      y
    } = position;
    p.push();
    p.translate(x, y);
    //renderBorder(p);
    component.renderComponent(p);
    component.renderChildren(p);
    p.pop();
  }
  function renderComponent(p) {
    renderBorder(p);
  }
  function renderBorder(p) {
    const {
      size
    } = component;
    const {
      width,
      height
    } = size;
    p.stroke([0, 0, 255, 192]);
    p.strokeWeight(2);
    p.noFill();
    p.rectMode(p.CORNER);
    p.rect(0, 0, width, height);
  }
  function renderChildren(p) {
    Array.from(children).forEach(child => child.render(p));
  }
  function calculatePosition(p) {
    const {
      parent,
      alignment,
      position,
      size
    } = component;
    const {
      x: alignX,
      y: alignY
    } = alignment;
    const {
      width,
      height
    } = size;
    const {
      width: sceneWidth,
      height: sceneHeight
    } = p;
    let x = null,
      y = null;
    switch (alignX) {
      case Align.None:
        x = position.x;
        break;
      case Align.Left:
        x = parent ? parent.position.x : 0;
        break;
      case Align.Right:
        x = parent ? parent.position.x + parent.size.width - width : sceneWidth - width;
        break;
      case Align.Center:
        x = parent ? parent.position.x + (parent.size.width - width) / 2 : (sceneWidth - width) / 2;
        break;
      default:
        throw new Error("Invalid x-align value! " + alignX);
    }
    switch (alignY) {
      case Align.None:
        y = position.y;
        break;
      case Align.Top:
        y = parent ? parent.position.y : 0;
        break;
      case Align.Bottom:
        y = parent ? parent.position.y + parent.size.height - height : sceneHeight - height;
        break;
      case Align.Center:
        y = parent ? parent.position.y + (parent.size.height - height) / 2 : (sceneHeight - height) / 2;
        break;
      default:
        throw new Error("Invalid y-align value! " + alignY);
    }
    position.x = x;
    position.y = y;
  }
  function componentAt(x, y, path = []) {
    const {
      position,
      size
    } = component;
    const {
      x: minX,
      y: minY
    } = position;
    const {
      width,
      height
    } = size;
    const maxX = minX + width,
      maxY = minY + height;
    if (x < minX || x > maxX || y < minY || y > maxY) return null;
    path.push(component);
    for (const child of Array.from(children)) {
      const childX = x - minX,
        childY = y - minY;
      const comp = child.componentAt(childX, childY, path);
      if (comp) return comp;
    }
    return component;
  }
  function handleEvent(event, p, evt) {
    if (!component.canFire(event)) return false;
    component.fire(event, p, evt);
    return true;
  }
  return component;
}