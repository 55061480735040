import ChooseLevelScreen from "./chooseLevel";
import StartLevelScreen from "./start";
import GameScreen from "./game";
import GameOverScreen from "./gameOver";
import EditorScreen from "./editor";
export default {
  chooseLevel: ChooseLevelScreen,
  start: StartLevelScreen,
  game: GameScreen,
  gameOver: GameOverScreen,
  editor: EditorScreen
};