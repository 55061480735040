import Game from "game";
import Deferred from "core/deferred";
import Log from "core/log";
const log = Log("app.main");
export default function Main(level, view) {
  const game = Game(view.scene, level, onCancel, onDone);
  let deferred = null;
  let callCount = 0;
  let result = level;
  function execute() {
    callCount++;
    deferred = new Deferred();
    setEnabled(true);
    return deferred.promise;
  }
  function setEnabled(enabled) {
    if (!enabled && deferred) {
      deferred.resolve(result);
    }
    if (enabled) {
      view.start(level);
      game.start();
    } else {
      view.stop();
    }
  }
  function onCancel() {
    result = null;
    setEnabled(false);
  }
  function onDone() {
    setEnabled(false);
  }
  return {
    level,
    execute
  };
}