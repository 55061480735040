import Gamepad, { Event } from "core/gamepad";
import Element from "app/element";
export default function QuestionHandler(level, physics) {
  const {
    survey
  } = level;
  if (!survey.hasQuestion()) return;
  const question = survey.getQuestion();
  const answers = survey.getAnswers();
  const overlay = Element("#game-question");
  const questionText = Element("#game-question-text", overlay);
  const questionAnswers = Element("#game-question-answers", overlay);
  let selectedAnswer = null; //Element(".selected", questionAnswers);

  physics.setEnabled(false);
  questionText.innerHTML = question;
  questionAnswers.innerHTML = "";
  answers.forEach(answer => questionAnswers.appendChild(makeAnswer(answer)));
  overlay.setVisible(true);
  Gamepad.on(Event.ButtonDown, onButtonPressed);
  Gamepad.on(Event.Move, onAxisMove);
  document.addEventListener("keydown", onKeyDown);
  function onKeyDown(evt) {
    if (evt.repeat) return;
    selectedAnswer && selectedAnswer.setSelected(false);
    switch (evt.keyCode) {
      case 13:
        // RETURN
        submitAnswer();
        break;
      case 37:
      case 65:
        // Left
        moveLeft();
        break;
      case 39:
      case 68:
        // Right
        moveRight();
        break;
      case 27:
        // Esc
        cancel();
        break;
    }
    selectedAnswer && selectedAnswer.setSelected(true);
  }
  function onButtonPressed({
    index
  }) {
    selectedAnswer && selectedAnswer.setSelected(false);
    switch (index) {
      case 14:
        // left
        moveLeft();
        break;
      case 15:
        // right
        moveRight();
        break;
      case 0:
      case 1:
      case 2:
      case 3:
        submitAnswer();
        break;
    }
    selectedAnswer && selectedAnswer.setSelected(true);
  }
  function onAxisMove({
    index,
    value
  }) {
    if (index != 0) return;
    selectedAnswer && selectedAnswer.setSelected(false);
    if (value < -0.5) moveLeft();else if (value > 0.5) moveRight();
    selectedAnswer && selectedAnswer.setSelected(true);
  }
  function moveLeft() {
    if (selectedAnswer && selectedAnswer.previousElementSibling) selectedAnswer = Element(selectedAnswer.previousElementSibling);else selectedAnswer = Element(questionAnswers.firstElementChild);
  }
  function moveRight() {
    if (selectedAnswer && selectedAnswer.nextElementSibling) selectedAnswer = Element(selectedAnswer.nextElementSibling);else selectedAnswer = Element(questionAnswers.lastElementChild);
  }
  function submitAnswer() {
    if (!selectedAnswer) return;
    document.removeEventListener("keydown", onKeyDown);
    survey.setAnswer(selectedAnswer.innerHTML);
    if (selectedAnswer) {
      selectedAnswer.setSelected(false);
      selectedAnswer = null;
    }
    const nextExists = survey.gotoNextQuestion();
    if (!nextExists) {
      level.end();
    }
    overlay.setVisible(false);
    Gamepad.off(Event.ButtonDown, onButtonPressed);
    physics.setEnabled(true);
  }
  function cancel() {
    document.removeEventListener("keydown", onKeyDown);
    Gamepad.off(Event.ButtonDown, onButtonPressed);
    overlay.setVisible(false);
    physics.setEnabled(true);
  }
}

// <div class="game-question-answer">Ja</div>
// <div class="game-question-answer">Nein</div>

function makeAnswer(answer) {
  const element = document.createElement("div");
  element.classList.add("game-question-answer");
  element.innerHTML = answer;
  return element;
}