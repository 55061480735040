const Input = {
  keys: {
    Space: 32,
    A: 65,
    D: 68,
    S: 83,
    Escape: 27
  },
  gamepad: {
    A: 0,
    B: 1,
    C: 2,
    D: 3,
    dpad: {
      Left: 14,
      Right: 15,
      Up: 16,
      Down: 17
    }
  }
};
export default Input;