import View from "core/view";
import Scene, { SceneToWindowFitter } from "core/scene";
export default function EditorView(element) {
  const view = View({
    element
  });
  const scene = new Scene("editor-main");
  SceneToWindowFitter(scene);
  function start(level) {
    scene.start();
    setTimeout(() => {
      scene.focus();
      scene.toast.show(level.name);
    }, 100);
  }
  function stop() {
    scene.stop();
  }
  return Object.assign(view, {
    start,
    stop,
    scene
  });
}