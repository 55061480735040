export async function get(url) {
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();
    req.addEventListener("load", LoadHandler(req, resolve, reject));
    req.addEventListener("error", ErrorHandler(req, reject));
    req.open("GET", url);
    req.send();
  });
}
export async function post(url, data) {
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();
    req.addEventListener("load", LoadHandler(req, resolve, reject));
    req.addEventListener("error", ErrorHandler(req, reject));
    req.open("POST", url);
    req.setRequestHeader("Content-Type", "application/json");
    req.send(JSON.stringify(data));
  });
}
export async function put(url, data) {
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();
    req.addEventListener("load", LoadHandler(req, resolve, reject));
    req.addEventListener("error", ErrorHandler(req, reject));
    req.open("PUT", url);
    req.setRequestHeader("Content-Type", "application/json");
    req.send(JSON.stringify(data));
  });
}
export async function remove(url, data) {
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();
    req.addEventListener("load", LoadHandler(req, resolve, reject));
    req.addEventListener("error", ErrorHandler(req, reject));
    req.open("DELETE", url);
    req.setRequestHeader("Content-Type", "application/json");
    req.send(JSON.stringify(data));
  });
}
function LoadHandler(req, resolve, reject) {
  return evt => {
    const {
      status,
      statusText,
      response
    } = req;
    if (status != 200) {
      reject(`${status} ${statusText}`);
      return;
    }
    let result = response;
    try {
      result = JSON.parse(response);
    } catch (err) {}
    resolve(result);
  };
}
function ErrorHandler(req, reject) {
  return evt => reject(new Error("Unknown error!"));
}