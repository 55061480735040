import MainRenderer from "app/main-renderer";
export default function EditorRenderer(level) {
  const main = MainRenderer({
    level
  });
  const grid = GridRenderer(level);
  return p => {
    main(p);
    grid(p);
  };
}
function GridRenderer(level) {
  return p => {
    const {
      grid,
      offset
    } = level;
    const {
      width,
      height
    } = grid;
    const {
      x: offX,
      y: offY
    } = offset;
    p.noFill();
    p.stroke([0, 0, 0, 16]);
    const {
      columns,
      rows,
      tileSize
    } = grid;
    for (let col = 0; col < columns; col++) {
      const x = -offX + col * tileSize;
      p.line(x, -offY, x, -offY + height);
    }
    for (let row = 0; row < rows; row++) {
      const y = -offY + row * tileSize;
      p.line(-offX, y, -offX + width, y);
    }
  };
}