export function button(...classes) {
  return element("button", ...classes);
}
export function div(...classes) {
  return element("div", ...classes);
}
function element(tag, ...classes) {
  const el = document.createElement(tag);
  if (classes) classes.forEach(clazz => el.classList.add(clazz));
  el.content = function (html) {
    el.innerHTML = html;
    return el;
  };
  el.click = function (cb) {
    el.addEventListener("click", cb);
    return el;
  };
  return el;
}