import R from "core/resources";
import Grid from "../../core/grid";
const columns = 8;
const tileSize = 70;
export default function TileState() {
  const spriteCount = R.gfx.tiles.getSpriteCount();
  const rows = Math.ceil(spriteCount / columns);
  const grid = Grid({
    columns,
    rows,
    tileSize
  });
  const sceneWidth = columns * tileSize,
    sceneHeight = rows * tileSize;
  for (let i = 0; i < spriteCount; i++) {
    const {
      x,
      y
    } = grid.indexToGrid(i);
    const sprite = R.gfx.tiles.getSpriteByIndex(i);
    grid.setTile(x, y, sprite);
  }
  return {
    grid,
    scene: {
      width: sceneWidth,
      height: sceneHeight
    }
  };
}