import Preloader from "./preloader";
import Spritesheet from "./spritesheet";
import * as res from "../res";
import Log from "core/log";
const log = Log("R");
const Loader = {
  gfx: {
    entries: res.gfx,
    loader: "preloadImage",
    maker: (meta, image) => Spritesheet(meta, image)
  },
  //sfx : { entries : res.sfx, loader : "preloadSound", maker : (url, sound) => sound },
  fonts: {
    entries: res.fonts,
    loader: "preloadFont",
    maker: (url, font) => font
  }
};
const R = (() => {
  const resources = {
    gfx: {},
    sfx: {},
    fonts: {},
    init
  };
  function init() {
    log.info("Preloading assets ...");
    const preloader = Preloader();
    Object.keys(res).forEach(r => resources[r] = load(preloader, r));
    log.debug("Preloader executing ...");
    return preloader.run();
  }
  return resources;
})();
function load(preloader, section) {
  const object = {};
  const {
    entries,
    maker,
    loader
  } = Loader[section];
  log.debug(`Create preloader for ${section}.`);
  for (const [name, meta] of Object.entries(entries)) {
    preloader[loader](meta.url).then(data => onLoaded(name, meta, data)).catch(console.error);
  }
  function onLoaded(name, meta, data) {
    object[name] = maker(meta, data);
    log.debug(`Loaded ${name}.`);
  }
  return object;
}
export default R;