export function arrayToObject(array, mapper) {
  return array.reduce((obj, cur) => {
    const value = mapper(cur, obj);
    if (typeof value !== "undefined") obj[cur] = value;
    return obj;
  }, {});
}
export function makeTimeString(elapsedMillis) {
  const elapsedSecs = Math.floor(elapsedMillis / 1000);
  const seconds = elapsedSecs % 60;
  const minutes = Math.floor(elapsedSecs / 60);
  const minString = "" + minutes;
  const secString = seconds < 10 ? "0" + seconds : "" + seconds;
  return `${minString}:${secString}`;
}

// Events to method names, e.g: mouseMoved => onMouseMoved
export function prefix(prefix, cur) {
  return prefix + cur.charAt(0).toUpperCase() + cur.substring(1);
}