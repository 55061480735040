import View from "core/view";
export default function GameOverView(element) {
  const view = View({
    element
  });
  const thanks = element.querySelector("#gameOverThanks");
  const timeElement = element.querySelector("#gameOverTime");
  function setTime(millis) {
    timeElement.innerHTML = millis;
  }
  function setThanksVisible(visible) {
    if (visible) thanks.classList.remove("hidden");else thanks.classList.add("hidden");
  }
  element.addEventListener("keydown", evt => {
    view.resolve();
  });
  return Object.assign(view, {
    setThanksVisible,
    setTime
  });
}