import Controller from "./controller";
import Settings from "settings";
import Actions from "actions";
import FollowCamera from "./controller/followCamera";
export default function PlayerControl(scene, level) {
  const controllers = Controller(scene, level);
  const actions = Object.values(Actions.player);
  const {
    keys,
    gamepad: {
      buttons,
      axes
    }
  } = Settings.input;
  const axisX = axes[0];
  const axisY = axes[1];
  const follow = FollowCamera(scene, level);
  function setEnabled(enabled) {
    (enabled ? scene.render.on : scene.render.off)(update);
  }
  function update(p) {
    const {
      isKeyPressed,
      isButtonPressed,
      getAxes
    } = scene.input;
    const currentAxes = getAxes();
    const currentAxisXPresent = (0 in currentAxes);
    const currentAxisX = currentAxisXPresent ? currentAxes[0] : null;
    const currentAxisXActionIndex = !currentAxisXPresent || currentAxisX == 0 ? null : currentAxisX < 0 ? 0 : 1;
    const currentAxisYPresent = (0 in currentAxes);
    const currentAxisY = currentAxisYPresent ? currentAxes[1] : null;
    const currentAxisYActionIndex = !currentAxisYPresent || currentAxisY == 0 ? null : currentAxisY < 0 ? 0 : 1;
    const isOneButtonPressed = array => Array.isArray(array) ? array.some(entry => isButtonPressed(entry)) : false;
    follow(p);
    actions.forEach(action => {
      const actionKeys = keys[action];
      const actionGamepadButton = buttons[action];
      const currentAxisXAction = currentAxisXActionIndex != null ? axisX[currentAxisXActionIndex] : null;
      const currentAxisYAction = currentAxisYActionIndex != null ? axisY[currentAxisYActionIndex] : null;
      const active = actionKeys && actionKeys.find(key => isKeyPressed(key)) || isOneButtonPressed(actionGamepadButton);
      const ctrl = controllers[action];
      ctrl(active);
      if (currentAxisXPresent) {
        if (currentAxisXAction != null) controllers[currentAxisXAction](true);
      }
      if (currentAxisYPresent) {
        if (currentAxisYAction != null) controllers[currentAxisYAction](true);
      }
    });
  }
  return {
    setEnabled
  };
}