import GameOverView from "./view";
import { post } from "core/api";
import { makeTimeString } from "core/util";
export default function GameOverScreen(element) {
  const view = GameOverView(element);
  async function show(result) {
    const answers = result.survey.getAllAnswers();
    const timeMillis = result.timeEnded - result.timeStarted;
    const timeString = makeTimeString(timeMillis);
    view.setTime(timeString);
    view.setThanksVisible(false);
    await saveAnswers(answers);
    view.setThanksVisible(true);
    return await view.execute();
  }
  return Object.assign(view, {
    show
  });
}
async function saveAnswers(answers) {
  return post("/feedback", answers);
}