import ScreenModules from "screen";
function Screens() {
  const screens = initScreens();
  let screenShown = null;
  async function goto(screenName, data) {
    hideScreen(screenShown);
    screenShown = screenName;
    return showScreen(screenShown, data);
  }
  function hideScreen(screenName) {
    screenName && screens[screenName].classList.add("hidden");
  }
  function showScreen(screenName, data) {
    if (!screenName) return;
    if (!(screenName in screens)) throw new Error(`Unknown screen! (${screenName})`);
    const screen = screens[screenName];
    screen.classList.remove("hidden");
    screen.focus();
    return screen.show(data);
  }
  return {
    goto
  };
}
function initScreens() {
  const elements = document.querySelectorAll(".screen");
  const screens = {};
  elements.forEach(element => {
    const {
      id
    } = element;
    if (!(id in ScreenModules)) {
      console.warn(`Could not load screen module for element ${id}!`);
      return;
    }
    screens[id] = ScreenModules[id](element);
  });
  return screens;
}
export default Screens;