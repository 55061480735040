const AVG_COUNT = 60;
const Timing = (() => {
  const entries = {};
  function start(name) {
    const start = performance.now();
    function stop() {
      const end = performance.now();
      if (!(name in entries)) {
        entries[name] = {
          name,
          times: [],
          avg: null
        };
      }
      const entry = entries[name];
      const time = end - start;
      entry.times.push(time);
      while (entry.times.length > AVG_COUNT) entry.times.shift();
      if (entry.times.length == AVG_COUNT) entry.avg = entry.times.reduce((prev, cur) => prev + cur, 0) / AVG_COUNT;
    }
    return {
      stop
    };
  }
  return {
    entries,
    start
  };
})();
export default Timing;