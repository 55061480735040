const jumpForce = 14;
const KEY_JUMP = 32;
const BTN_JUMP = 0;
export default function Jump(scene, level) {
  const {
    input
  } = scene;
  const {
    player
  } = level;
  let jumpOnCoolDown = false;
  function jump() {
    if (!player.landed || jumpOnCoolDown) return;
    player.landed = false;
    jumpOnCoolDown = true;
    player.velocity.y -= jumpForce;
  }
  function update(jumpActive) {
    if (jumpOnCoolDown && !jumpActive && player.landed) jumpOnCoolDown = false;
    if (jumpActive && !jumpOnCoolDown) jump();
  }
  return update;
}