import WebStorage from "core/webstorage";
import ChooseLevelView from "./view";
import { get } from "core/api";
import AddController from "./controller/add";
import ChooseController from "./controller/choose";
import DeleteController from "./controller/delete";
export default function ChooseLevelScreen(element) {
  const view = ChooseLevelView(element);
  const storage = WebStorage("feedback-game.level-id");
  view.events.onAdd(AddController(view));
  view.events.onChoose(ChooseController(view));
  view.events.onDelete(DeleteController(view));
  async function show({
    ignoreSaved = false
  } = {}) {
    if (!ignoreSaved) {
      const lastLevelId = storage.get();
      if (lastLevelId != null) {
        try {
          return await loadLevel(lastLevelId);
        } catch (error) {
          alert(`Der Level ${lastLevelId} konnte nicht vom Server geladen werden!`);
        }
      }
    }
    await view.update();
    const levelId = await view.execute();
    storage.set(levelId);
    return loadLevel(levelId);
  }
  return Object.assign(element, {
    show
  });
}
async function loadLevel(id) {
  const result = await get("/levels/" + id);
  if (!result.success) throw new Error(result.details);
  return result.payload;
}