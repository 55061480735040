import p5 from "p5";
import Deferred from "../deferred";
import Input from "./input";
import Render from "./render";
import Toast from "./toast";
import GUI from "../gui";
import R from "core/resources";
let nextInstId = 0;
export default function Scene(containerId = null, width = 0, height = 0) {
  const id = `canvas-${containerId}`;
  const parent = document.querySelector(`#${containerId}`);
  const instId = nextInstId++;
  const render = Render();
  const input = Input(id);
  const toast = Toast();
  const gui = GUI(input);
  const size = {
    width: 0,
    height: 0
  };
  const scene = {
    parent,
    size,
    input,
    render,
    gui,
    toast,
    start,
    stop,
    resize,
    focus
  };
  let canvas = null;
  let instance = null;
  let lastFocused = null;
  let newSize = null;
  let resizeDeferred = null;
  input.onSetup(p => {
    canvas = p.createCanvas(width, height);
    canvas.id(id);
    canvas.elt.setAttribute("tabindex", "0");
    scene.size = {
      width,
      height
    };
    p.getAudioContext().suspend();
  });

  // input.onMousePressed(p => {
  //     console.log("starting audio");
  //     p.userStartAudio();
  //     R.sfx.leaves01.play();
  // })

  input.onDraw(p => {
    if (newSize) {
      const {
        width,
        height
      } = newSize;
      p.resizeCanvas(width, height);
      scene.size = {
        width,
        height
      };
      newSize = null;
      if (resizeDeferred) {
        resizeDeferred.resolve(scene.size);
        resizeDeferred = null;
      }
    }
    render.draw(p, scene);
    gui.render(p);
    toast.render(p);
  });
  function focus() {
    canvas.elt.focus();
  }
  function start() {
    instance = new p5(input.init, parent);
    lastFocused = document.activeElement;
    focus();
  }
  function stop() {
    instance && instance.remove();
    instance = null;
    lastFocused && lastFocused.focus();
    lastFocused = null;
  }
  function resize(size) {
    newSize = size;
    resizeDeferred = new Deferred();
    return resizeDeferred.promise;
  }
  return scene;
}
export function SceneToWindowFitter(scene) {
  scene.input.onSetup(updateSize);
  scene.input.onWindowResized(updateSize);
  function updateSize(p) {
    const {
      offsetWidth: width,
      offsetHeight: height
    } = scene.parent;
    scene.size = {
      width,
      height
    };
    p.resizeCanvas(width, height);
  }
  return scene.size;
}