import Timing from "../timing";
export default function Render() {
  const renderers = new Set();
  const on = renderer => renderers.add(renderer);
  const off = renderer => renderers.delete(renderer);
  function draw(p, scene) {
    const timer = Timing.start("render");
    Array.from(renderers).forEach(renderer => renderer(p, scene));
    timer.stop();
  }
  return {
    on,
    off,
    draw
  };
}