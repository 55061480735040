const playerCollisionMargin = 0.30;

/* Bounding box to collidingTilesIndex
    ####0+------+3####
    #    |      |    #
    #   1+      +4   #
    #    |      |    #
    ####2+------+5####
*/

export default function CollisionPhysics(level) {
  updateBoundingBox(level.player);
  let collidingTiles = determineCollidingTiles(level);
  resolveCollisionsY(level, [collidingTiles[2], collidingTiles[5]]);
  updateBoundingBox(level.player);
  collidingTiles = determineCollidingTiles(level);
  resolveCollisionsLeft(level, [collidingTiles[1], collidingTiles[2]]);
  resolveCollisionsRight(level, [collidingTiles[4], collidingTiles[5]]);
  collideWithBorderBottom(level);
}
function updateBoundingBox(player) {
  const {
    position,
    size: {
      width,
      height
    }
  } = player;
  const x1 = position.x + playerCollisionMargin * width;
  const y1 = position.y;
  const x2 = position.x + (1 - playerCollisionMargin) * width;
  const y2 = position.y + height;
  player.boundingBox = {
    x1,
    y1,
    x2,
    y2
  };
}
function determineCollidingTiles(level) {
  const {
    player: {
      boundingBox: {
        x1,
        y1,
        x2,
        y2
      }
    },
    grid
  } = level;
  const centerY = y1 + (y2 - y1) / 2;
  const points = [{
    x: x1,
    y: y1
  }, {
    x: x1,
    y: centerY
  }, {
    x: x1,
    y: y2
  }, {
    x: x2,
    y: y1
  }, {
    x: x2,
    y: centerY
  }, {
    x: x2,
    y: y2
  }];
  const tiles = points.map(point => {
    const gridPos = grid.levelToGrid(point);
    return {
      point,
      gridPos,
      tile: grid.getTile(gridPos.x, gridPos.y)
    };
  });
  return tiles;
}
function resolveCollisionsLeft(level, collidingTiles) {
  const {
    player: {
      position,
      velocity,
      boundingBox
    },
    grid
  } = level;
  if (velocity.x >= 0) return;
  const hasCollision = collidingTiles.find(entry => entry?.tile?.params?.blocking);
  if (!hasCollision) return;
  velocity.x = 0;
  const newX = grid.gridToLevel({
    x: hasCollision.gridPos.x + 1,
    y: 0
  }).x - (boundingBox.x1 - position.x) + 1;
  position.x = newX;
}
function resolveCollisionsRight(level, collidingTiles) {
  const {
    player: {
      position,
      velocity,
      boundingBox
    },
    grid
  } = level;
  if (velocity.x <= 0) return;
  const hasCollision = collidingTiles.find(entry => entry?.tile?.params?.blocking);
  if (!hasCollision) return;
  velocity.x = 0;
  const newX = grid.gridToLevel(hasCollision.gridPos).x - (boundingBox.x2 - position.x) - 1;
  position.x = newX;
}
function resolveCollisionsY(level, collidingTiles) {
  const {
    player,
    grid
  } = level;
  const {
    velocity,
    size
  } = player;
  if (velocity.y <= 0) return;
  const hasCollision = collidingTiles.find(entry => entry?.tile?.params?.walkable);
  if (!hasCollision) return;
  const currentX = hasCollision.point.x;
  const lastY = hasCollision.point.y - velocity.y;
  const lastGridPos = grid.levelToGrid({
    x: currentX,
    y: lastY
  });
  const transitionsDown = lastGridPos.y < hasCollision.gridPos.y;
  if (!transitionsDown) return;
  const gridY = hasCollision.gridPos.y;
  const {
    height
  } = size;
  player.velocity.y = 0;
  player.position.y = grid.gridToLevel({
    x: 0,
    y: gridY
  }).y - height - 1;
  player.landed = true;
}
function collideWithBorderBottom(level) {
  const {
    player
  } = level;
  const {
    position
  } = player;
  if (level.dyingCount == 0) {
    if (position.y > level.grid.height) {
      level.dyingCount++;
    }
  } else {
    level.dyingCount += 8;
    if (level.dyingCount > 255) {
      level.dyingCount = 0;
      level.reset();
    } else {
      player.opacity = 255 - level.dyingCount;
    }
  }
}