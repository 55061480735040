import Actions from "./actions";
import Input from "./input";
const Settings = {
  editor: {
    enabled: true
  },
  levelChooser: {
    enabled: false
  },
  input: {
    keys: {
      [Actions.player.MoveLeft]: [Input.keys.A],
      [Actions.player.MoveRight]: [Input.keys.D],
      [Actions.player.Jump]: [Input.keys.Space],
      [Actions.player.Cancel]: [Input.keys.Escape]
    },
    gamepad: {
      buttons: {
        [Actions.player.MoveLeft]: [Input.gamepad.dpad.Left],
        [Actions.player.MoveRight]: [Input.gamepad.dpad.Right],
        [Actions.player.Jump]: [Input.gamepad.A, Input.gamepad.B, Input.gamepad.C, Input.gamepad.D, Input.gamepad.dpad.Up]
      },
      axes: {
        0: [Actions.player.MoveLeft, Actions.player.MoveRight],
        1: [Actions.player.Jump, null]
      }
    }
  },
  debug: {
    player: {
      renderPlayerBox: false,
      renderBoundingBox: false
    }
  }
};
export default Settings;