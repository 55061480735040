import R from "core/resources";
import Settings from "settings";
const walkDivider = 2;
const debugCfg = Settings.debug.player;
const playerVariants = ["player1", "player2", "player3"];
export default function PlayerRenderer(player) {
  let sprites = null;
  setPlayerVariant(0);
  function setPlayerVariant(newIndex) {
    console.log("setplayervariant", newIndex);
    if (newIndex < 0 || newIndex >= playerVariants) return;
    const variant = playerVariants[newIndex];
    sprites = makeSprites(variant);
  }
  const isReversing = () => player.velocity.x < 0;
  const isResting = () => player.velocity.x == 0;
  function determineSprite() {
    const {
      landed,
      ducking
    } = player;
    const resting = isResting();
    if (landed) {
      if (resting) return ducking ? sprites.duck : sprites.stand;
      return sprites.walk[walkIndex];
    }
    return resting ? sprites.stand : sprites.jump;
  }
  let walkIndex = 0;
  function progressWalkAnimation(p) {
    if (p.frameCount % walkDivider == 0) {
      walkIndex = (++walkIndex + sprites.walk.length) % sprites.walk.length;
    }
  }
  function render(p, scene) {
    const {
      position: {
        x,
        y
      }
    } = player;
    const sprite = determineSprite();
    const reversing = isReversing();
    const spriteOffsetX = (player.size.width - sprite.width) / 2;
    const spriteOFfsetY = player.size.height - sprite.height;
    p.push();
    p.translate(x, y);
    p.translate(spriteOffsetX, spriteOFfsetY);
    if (reversing) {
      p.translate(sprite.width, 0);
      p.scale(-1, 1);
    }
    p.tint(255, player.opacity);
    p.image(sprite, 0, 0);
    p.pop();
    if (debugCfg.renderPlayerBox) renderPlayerBox(p, player);
    if (debugCfg.renderBoundingBox) renderBoundingBox(p, player);
    progressWalkAnimation(p);
  }
  return Object.assign(render, {
    setPlayerVariant
  });
}
function renderBoundingBox(p, player) {
  if (!("boundingBox" in player)) return;
  const {
    boundingBox: {
      x1,
      y1,
      x2,
      y2
    }
  } = player;
  p.rectMode(p.CORNERS);
  p.stroke([255, 0, 255]);
  p.noFill();
  p.rect(x1, y1, x2, y2);
}
function renderSpriteBox(p, player, sprite) {
  const {
    position: {
      x,
      y
    }
  } = player;
  const {
    width,
    height
  } = sprite;
  p.rectMode(p.CORNER);
  p.stroke([255, 255, 0]);
  p.noFill();
  p.rect(x, y, width, height);
}
function renderPlayerBox(p, player) {
  const {
    position: {
      x,
      y
    },
    size: {
      width,
      height
    }
  } = player;
  p.rectMode(p.CORNER);
  p.stroke([0, 255, 255]);
  p.noFill();
  p.rect(x, y, width, height);
}
function makeSprites(variant) {
  const gfx = R.gfx[variant];
  const sprites = {
    stand: gfx.getSprite("player_front").sprite,
    jump: gfx.getSprite("player_jump").sprite,
    duck: gfx.getSprite("player_duck").sprite,
    walk: Array(11).fill(0).map((_, i) => gfx.getSprite(`player_walk${("" + (i + 1)).padStart(2, 0)}`).sprite)
  };
  return sprites;
}