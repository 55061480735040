import Log from "core/log";
const log = Log("fsm");
export default function Fsm({
  State,
  Transition
}) {
  const machine = {
    State,
    Transition,
    currentState: null,
    on,
    fire
  };
  const handlers = {};
  Object.values(State).forEach(t => handlers[t] = new Set());
  for (const transitionName of Object.keys(Transition)) {
    machine[transitionName] = makeTransition(transitionName);
  }
  function makeTransition(transitionName) {
    return data => fire(transitionName, data);
  }
  function fire(transitionName, data) {
    if (!(transitionName in Transition)) throw new Error(`Unknown transition ${transitionName}!`);
    const transition = Transition[transitionName];
    if (machine.currentState != transition.from) throw `Cannot ${transitionName} from state ${machine.currentState}!`;
    machine.currentState = transition.to;
    log.info(`transition ${transitionName} from ${transition.from} to ${transition.to}`);
    dispatch(data);
  }
  function on(state, callback) {
    if (!(state in handlers)) throw `Illegal state! ${state}`;
    handlers[state].add(callback);
  }
  function dispatch(data) {
    Array.from(handlers[machine.currentState]).forEach(callback => callback(data));
  }
  return machine;
}