import Deferred from "./deferred";
import p5 from "p5";
import Log from "core/log";
const log = Log("Preloader");
export default function Preloader() {
  const preloads = {
    image: [],
    sound: [],
    font: []
  };
  function preloadImage(url) {
    return preload(preloads.image, url);
  }
  function preloadFont(url) {
    return preload(preloads.font, url);
  }
  function preloadSound(url) {
    return preload(preloads.sound, url);
  }
  function preload(array, url) {
    const deferred = new Deferred();
    array.push({
      url,
      deferred
    });
    return deferred.promise;
  }
  function run() {
    const makeFunctionName = type => "load" + type.substring(0, 1).toUpperCase() + type.substring(1);
    return new Promise((resolve, reject) => {
      new p5(p => {
        p.setup = () => {
          const types = Object.keys(preloads);
          types.forEach(type => {
            const entries = preloads[type];
            const func = p[makeFunctionName(type)];
            entries.forEach(({
              url,
              deferred
            }) => {
              log.debug(`Preloading ${url}.`);
              func(url, payload => deferred.resolve(payload), error => deferred.reject(error));
            });
          });
          Promise.all(Object.values(types).flatMap(type => preloads[type]).map(e => e.deferred.promise)).then(() => {
            log.info(`Done.`);
            p.remove();
            resolve();
          });
        };
      });
    });
  }
  return {
    preloadImage,
    preloadFont,
    preloadSound,
    run
  };
}