import Component, { Align } from "./component";
const SCENE_EVENTS = ["mouseMoved", "mousePressed", "mouseReleased"];
export default function GUI(input) {
  const root = Component({
    name: "root",
    alignment: {
      x: Align.Left,
      y: Align.Top
    },
    fillScene: true
  });
  SCENE_EVENTS.forEach(eventName => {
    input.on(eventName, makeEventHandler(eventName));
  });
  function makeEventHandler(event) {
    let lastShit = null;
    return (p, evt) => {
      const {
        mouseX,
        mouseY
      } = p;
      const path = [];
      const componentAt = root.componentAt(mouseX, mouseY, path);
      const changed = lastShit != componentAt;
      if (changed) {
        lastShit && lastShit.fireComponentOut();
        componentAt && componentAt.fireComponentIn();
      }
      lastShit = componentAt;
      if (!componentAt) return false;
      //console.log(path.map(p => p.name));
      return componentAt.handleEvent(event, p, evt);
    };
  }
  return root;
}