import Deferred from "core/deferred";
import { get } from "core/api";
import { div, button } from "core/html";
import Events from "core/events";
import View from "core/view";
export const Event = {
  Add: "add",
  Choose: "choose",
  Delete: "delete"
};
export default function ChooseLevelView(element) {
  const view = View({
    element,
    events: Event
  });
  const list = element.querySelector("#loader-list");
  const addButton = element.querySelector(".header button.add");
  addButton.addEventListener("click", onAddLevel);
  async function update() {
    return get("/levels").then(buildSelection);
  }
  function onAddLevel() {
    view.events.fireAdd();
  }
  function onChooseLevel(id) {
    view.events.fireChoose(id);
  }
  function onDeleteLevel(id, name) {
    view.events.fireDelete(id, name);
  }
  function buildSelection(levels) {
    list.innerHTML = "";
    for (const [id, name] of Object.entries(levels)) {
      list.appendChild(Entry(id, name));
    }
  }
  function Entry(id, name) {
    const entry = div("entry");
    const label = div("label").content(name).click(() => onChooseLevel(id));
    const deleteButton = button().content("X").click(() => onDeleteLevel(id, name));
    entry.appendChild(label);
    entry.appendChild(deleteButton);
    return entry;
  }
  return Object.assign(view, {
    update
  });
}