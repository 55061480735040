const gravityForce = 0.6;
export default function KinematicsPhysics(level) {
  const {
    player,
    grid
  } = level;
  applyGravity(player);
  applyVelocity(player, grid);
}
function applyGravity(player) {
  const {
    velocity
  } = player;
  velocity.y += gravityForce;
}
function applyVelocity(player, grid) {
  const {
    velocity,
    position
  } = player;
  const {
    x,
    y
  } = velocity;
  player.lastPosition = {
    x: position.x,
    y: position.y
  };
  position.x += x;
  position.y += y;
  position.x = Math.max(0, position.x);
  position.x = Math.min(grid.width - player.size.width, position.x);
}