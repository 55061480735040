import Editor from "editor";
import Deferred from "core/deferred";
import Log from "core/log";
import { put } from "core/api";
const log = Log("editor.main");
export default function Main(level, view) {
  const editor = Editor(view.scene, level, onExit);
  let deferred = null;
  let callCount = 0;
  let result = level;
  function execute() {
    callCount++;
    deferred = new Deferred();
    show();
    return deferred.promise;
  }
  function show() {
    view.start(level);
    editor.setEnabled(true);
  }
  function onExit(levelData) {
    result = levelData;
    editor.setEnabled(false);
    saveLevel(levelData).then(() => {
      view.stop();
      deferred.resolve(result);
    });
  }
  function saveLevel(levelData) {
    const {
      scene
    } = view;
    scene.toast.show("Saving ...");
    log.debug("Saving level ...");
    return put("/levels", levelData).then(handle).catch(error);
    function handle({
      success,
      details = null
    }) {
      if (success) {
        log.info("Level saved.");
        scene.toast.show("Saved.");
      } else {
        log.error(details);
        scene.toast.show(details, "error");
      }
    }
    function error(err) {
      scene.toast.show(err, "error");
    }
  }
  return {
    level,
    execute
  };
}