import Component, { Align } from "core/gui/component";
export default function Icon({
  sprite,
  label,
  action
}) {
  const width = parseInt(sprite.width);
  const height = parseInt(sprite.height);
  const component = new Component({
    name: `Icon ${label}`,
    size: {
      width,
      height
    }
  });
  let hovered = false;
  component.onComponentIn(() => hovered = true);
  component.onComponentOut(() => hovered = false);
  component.renderComponent = p => {
    p.tint(hovered ? 255 : 200);
    p.image(sprite, 0, 0);
  };
  component.onMousePressed(() => {
    action();
  });
  return component;
}
;