import Fsm from "./state";
const State = {
  ChooseLevel: "chooseLevel",
  Start: "start",
  Game: "game",
  Editor: "editor",
  GameOver: "gameOver"
};
const Transition = {
  init: {
    from: null,
    to: State.ChooseLevel
  },
  levelChosen: {
    from: State.ChooseLevel,
    to: State.Start
  },
  start: {
    from: State.Start,
    to: State.Game
  },
  edit: {
    from: State.Start,
    to: State.Editor
  },
  endEdit: {
    from: State.Editor,
    to: State.Start
  },
  quit: {
    from: State.Start,
    to: State.ChooseLevel
  },
  cancel: {
    from: State.Game,
    to: State.Start
  },
  done: {
    from: State.Game,
    to: State.GameOver
  },
  reset: {
    from: State.GameOver,
    to: State.Start
  }
};
const ScreenState = Fsm({
  State,
  Transition
});
export default ScreenState;
export { State, Transition };