export default function Resizer(levelData, newSize) {
  const {
    columns: oldColumns,
    rows: oldRows,
    tiles: oldTiles
  } = levelData;
  const {
    columns: newColumns,
    rows: newRows
  } = newSize;
  const newTiles = [];
  for (let column = 0; column < newColumns; column++) {
    if (column < oldColumns) newTiles.push(resizeColumn(oldTiles[column], oldRows, newRows));else newTiles.push(new Array(newRows).fill(0).map(() => ({})));
  }
  const newLevelData = {
    ...levelData,
    tiles: newTiles,
    columns: newColumns,
    rows: newRows
  };
  return newLevelData;
}
function resizeColumn(oldColumn, oldRows, newRows) {
  const newColumn = [];
  for (let row = 0; row < newRows; row++) {
    if (row < oldRows) newColumn.push(oldColumn[row]);else newColumn.push({});
  }
  return newColumn;
}