export default function Spritesheet({
  url,
  sprites: spritesMeta
}, image) {
  let sprites = cutSpritesheet(image, spritesMeta);
  const sheet = {
    getSprite,
    getSpriteNameByIndex,
    getSpriteByIndex,
    getSpriteCount,
    getIndexByName
  };
  function getSprite(name) {
    return name in sprites ? sprites[name] : null;
  }
  function getSpriteNameByIndex(index) {
    return Object.keys(sprites)[index];
  }
  function getSpriteByIndex(index) {
    return Object.values(sprites)[index];
  }
  function getSpriteCount() {
    return sprites ? Object.keys(sprites).length : 0;
  }
  function getIndexByName(name) {
    const names = Object.keys(sprites);
    return names.indexOf(name);
  }
  return sheet;
}
function cutSpritesheet(image, spritesMeta) {
  const sprites = {};
  spritesMeta.forEach(({
    name,
    x,
    y,
    width,
    height,
    params = null
  }) => {
    const sprite = image.get(x, y, width, height);
    sprites[name] = {
      name,
      sprite,
      params
    };
  });
  return sprites;
}