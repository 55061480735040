import R from "core/resources";
import Component, { Align } from "core/gui/component";
export default function ItemsDisplay(player) {
  const component = Component({
    alignment: {
      x: Align.Right,
      y: Align.Top
    },
    size: {
      width: 150,
      height: 150
    }
  });
  const font = R.fonts.steelfish;
  component.renderComponent = p => {
    const {
      items
    } = player;
    const {
      width
    } = component.size;
    let y = 0;
    for (const [name, count] of Object.entries(items)) {
      const {
        sprite
      } = R.gfx.items.getSprite(name);
      const spriteX = width - sprite.width;
      const spriteY = y + sprite.height / 2;
      renderItem(p, sprite, spriteX, spriteY);
      renderCount(p, count, spriteX, spriteY);
      y += sprite.height;
    }
  };
  function renderItem(p, sprite, x, y) {
    p.image(sprite, x, y);
  }
  function renderCount(p, count, x, y) {
    if (count > 1) {
      const text = "" + count;
      p.noStroke();
      p.fill(255);
      p.textSize(32);
      p.textAlign(p.RIGHT, p.CENTER);
      p.textFont(font);
      p.text(text, x, y);
    }
  }
  return component;
}