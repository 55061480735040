import Gamepad, { Event } from "core/gamepad";
const KEY_RESET = 1000;
const BTN_RESET = 9;
export default function Reset(scene, level) {
  Gamepad.on(Event.ButtonDown, ({
    index
  }) => {
    if (index != BTN_RESET) return;
    level.reset();
  });
}