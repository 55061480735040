import Screens from "core/screens";
import ScreenState, { State } from "core/screenState";
export default function ScreenManager() {
  const screens = Screens();
  ScreenState.on(State.ChooseLevel, onChooseLevel);
  ScreenState.on(State.Start, onStart);
  ScreenState.on(State.Game, onGame);
  ScreenState.on(State.GameOver, onGameOver);
  ScreenState.on(State.Editor, onEditor);
  ScreenState.init({
    ignoreSaved: false
  });
  let currentLevel = null;
  async function onChooseLevel(data) {
    const level = await screens.goto(State.ChooseLevel, data);
    currentLevel = level;
    ScreenState.levelChosen(level);
  }
  async function onStart() {
    const goto = await screens.goto(State.Start);
    const payload = goto == "quit" ? {
      ignoreSaved: true
    } : currentLevel;
    ScreenState.fire(goto, payload);
  }
  async function onGame(data) {
    const result = await screens.goto(State.Game, data);
    if (result) {
      ScreenState.done(result);
    } else {
      ScreenState.cancel();
    }
  }
  async function onEditor(data) {
    const result = await screens.goto(State.Editor, data);
    if (result) currentLevel = result;
    ScreenState.endEdit(result);
  }
  async function onGameOver(data) {
    const result = await screens.goto(State.GameOver, data);
    ScreenState.reset();
  }
}