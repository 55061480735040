import R from "core/resources";
import SpriteChooser from "./chooser/spritechooser";
import ResizeDialog, { Resizer } from "./resize";
export default function TileTool(scene, level, onExit = () => {}) {
  const {
    grid
  } = level;
  const spriteChooser = SpriteChooser();
  const resizeDialog = ResizeDialog();
  let lastMousePosition = null;
  let spriteToSet = null;
  let panning = false;
  let enabled = false;
  function setEnabled(newEnabled) {
    enabled = newEnabled;
    if (enabled) {
      scene.render.on(render);
    } else {
      scene.render.off(render);
    }
  }
  function render(p, scene) {
    if (!enabled || !lastMousePosition || !spriteToSet) return;
    const {
      type,
      index
    } = spriteToSet;
    const cellScreen = level.gridToScreen(level.screenToGrid(lastMousePosition));
    const {
      sprite
    } = R.gfx[type].getSpriteByIndex(index);
    p.push();
    p.tint(255, 255, 255, 128);
    p.image(sprite, cellScreen.x, cellScreen.y);
    p.pop();
  }
  scene.input.onMousePressed((p, evt) => {
    if (!enabled) return;
    const ctrlIsPressed = evt.getModifierState("Control");
    switch (p.mouseButton) {
      case p.LEFT:
        if (ctrlIsPressed) removeAtMouse();else setAtMouse(spriteToSet);
        break;
      case p.CENTER:
        pickTile(ctrlIsPressed);
      case p.RIGHT:
        panning = true;
        break;
    }
    function setAtMouse(toSet) {
      if (!toSet || !lastMousePosition) return;
      const mouseCell = level.screenToGrid(lastMousePosition);
      const {
        x,
        y
      } = mouseCell;
      const {
        type,
        index
      } = toSet;
      const sprite = R.gfx[type].getSpriteByIndex(index);
      if (type == "tiles") grid.setTile(x, y, sprite);else grid.setItem(x, y, sprite);
      grid.commit();
    }
    function removeAtMouse() {
      if (!lastMousePosition) return;
      const mouseCell = level.screenToGrid(lastMousePosition);
      const {
        x,
        y
      } = mouseCell;
      grid.setTile(x, y, null);
      grid.setItem(x, y, null);
      grid.commit();
    }
    function pickTile(pickItem) {
      if (!lastMousePosition) return;
      const mouseCell = level.screenToGrid(lastMousePosition);
      const {
        x,
        y
      } = mouseCell;
      const type = pickItem ? "items" : "tiles";
      const object = pickItem ? grid.getItem(x, y) : grid.getTile(x, y);
      if (!object) {
        spriteToSet = null;
        return;
      }
      spriteToSet = {
        type,
        index: R.gfx[type].getIndexByName(object.name)
      };
    }
    lastMousePosition = {
      x: p.mouseX,
      y: p.mouseY
    };
  });
  scene.input.onMouseReleased((p, evt) => {
    if (!enabled) return;
    switch (p.mouseButton) {
      case p.RIGHT:
        panning = false;
        break;
    }
  });
  scene.input.onMouseWheel((p, evt) => {
    if (!enabled) return;
    if (!spriteToSet) return;
    const {
      type,
      index
    } = spriteToSet;
    const spriteToSetChange = Math.sign(evt.delta);
    const spriteCount = R.gfx[type].getSpriteCount();
    spriteToSet.index = (index + spriteCount + spriteToSetChange) % spriteCount;
  });
  scene.input.onMouseMoved(p => {
    if (!enabled) return;
    lastMousePosition = {
      x: p.mouseX,
      y: p.mouseY
    };
  });
  scene.input.onMouseDragged((p, evt) => {
    if (!enabled) return;
    if (lastMousePosition && panning) {
      const {
        x,
        y
      } = lastMousePosition;
      const dx = p.mouseX - x,
        dy = p.mouseY - y;
      let newOffX = level.offset.x - dx;
      let newOffY = level.offset.y - dy;
      newOffX = Math.max(0, Math.min(grid.width - p.width, newOffX));
      newOffY = Math.max(0, Math.min(grid.height - p.height, newOffY));
      level.offset.x = newOffX;
      level.offset.y = newOffY;
    }
    lastMousePosition = {
      x: p.mouseX,
      y: p.mouseY
    };
  });
  scene.input.onMouseOut(p => {
    if (!enabled) return;
    if (!panning) lastMousePosition = null;
  });
  scene.input.onKeyPressed((p, evt) => {
    if (!enabled) return;
    switch (evt.keyCode) {
      case 81:
        // q - show sprite chooser
        openSpriteChooser();
        break;
      case 82:
        // r - resize level
        evt.preventDefault();
        openResizer();
        break;
      case 80:
        // p - reposition player
        evt.preventDefault();
        repositionPlayer();
    }
  });
  function openSpriteChooser(initial = spriteToSet) {
    spriteChooser.open(initial).then(toSet => spriteToSet = toSet).catch(() => {});
  }
  function openResizer() {
    const levelData = level.toData();
    resizeDialog.open(levelData).then(size => Resizer(levelData, size)).then(onExit);
  }
  function repositionPlayer() {
    if (!lastMousePosition) return;
    const {
      player: {
        position,
        size: {
          width,
          height
        }
      }
    } = level;
    const {
      x: mouseX,
      y: mouseY
    } = lastMousePosition;
    const playerX = mouseX - width / 2,
      playerY = mouseY - height;
    const {
      x,
      y
    } = level.screenToLevel({
      x: playerX,
      y: playerY
    });
    Object.assign(position, {
      x,
      y
    });
  }
  return {
    setEnabled,
    openSpriteChooser
  };
}