import R from "core/resources";
import Log, { LogLevel } from "core/log";
import ScreenManager from "core/screen-manager";
Log.level = LogLevel.debug;
const log = Log("main");
log.info("Feedback Game client up.");
R.init().then(() => {
  log.info("Preload done. App starting ...");
  const mode = window.engineMode;
  switch (mode) {
    case "game":
      ScreenManager();
      break;
    case "editor":
      log.warning("Editor mode: Doing nothing so far!");
      break;
    default:
      log.error(`Unknown mode! (${mode})`);
  }
});
document.body.oncontextmenu = evt => {
  return false;
};