import R from "core/resources";
import Component, { Align } from "core/gui/component";
export default function LevelBox(level) {
  const component = new Component({
    name: "LevelBox",
    alignment: {
      x: Align.Right,
      y: Align.Top
    },
    size: {
      width: 100,
      height: 32
    }
  });
  const font = R.fonts.steelfish;
  const cleanColor = [255, 255, 255];
  const dirtyColor = [255, 64, 64];
  const fontSize = 48;
  const margin = 20;
  component.renderComponent = renderLevelName;
  function renderLevelName(p) {
    const {
      width
    } = component.size;
    const name = level.name;
    const isDirty = level.grid.isDirty();
    const color = isDirty ? dirtyColor : cleanColor;
    p.fill(color);
    p.noStroke();
    p.textAlign(p.RIGHT, p.TOP);
    p.textFont(font);
    p.textSize(fontSize);
    p.text(name, width - margin, margin);
    p.rect(0, 0, width, component.size.height);
  }
  return component;
}