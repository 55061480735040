import questions from "questions";
export default function Survey() {
  const answers = JSON.parse(JSON.stringify(questions));
  let currentQuestionIndex = 0;
  function getQuestion() {
    return answers[currentQuestionIndex].text;
  }
  function getAnswers() {
    return answers[currentQuestionIndex].answers;
  }
  function setAnswer(answer) {
    answers[currentQuestionIndex].answer = answer;
  }
  function hasAssistance() {
    return !!answers[currentQuestionIndex].assistanceIf;
  }
  function needsAssistance(answer) {
    if (!hasAssistance) return false;
    return answers[currentQuestionIndex].assistanceIf == answer;
  }
  function gotoNextQuestion() {
    currentQuestionIndex++;
    return hasQuestion();
  }
  function getAllAnswers() {
    return answers;
  }
  function hasQuestion() {
    return currentQuestionIndex < answers.length;
  }
  return {
    getQuestion,
    setAnswer,
    getAnswers,
    getAllAnswers,
    gotoNextQuestion,
    hasQuestion,
    hasAssistance,
    needsAssistance
  };
}