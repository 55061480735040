import { remove } from "core/api";
export default function DeleteController(view) {
  return async (id, name) => {
    console.log("delete", id, name);
    if (!confirmDelete(name)) return;
    try {
      await remove("/levels", {
        id
      });
      view.update();
    } catch (err) {
      console.error(err);
      alert(err);
    }
  };
}
function confirmDelete(name) {
  return confirm(`"${name}" wirklich löschen?`);
}