import Grid from "./grid";
import Player from "game/player";
import Survey from "app/survey";
export default function Level(data) {
  const levelData = JSON.parse(JSON.stringify(data));
  const survey = Survey();
  const {
    name,
    id,
    columns,
    rows,
    tileSize,
    player: playerData
  } = levelData;
  const grid = Grid(levelData);
  const player = Player(playerData);
  const offset = {
    x: 0,
    y: 0
  };
  const size = {
    width: columns * tileSize,
    height: rows * tileSize
  };
  const level = {
    name,
    id,
    offset,
    size,
    grid,
    player,
    timeStarted: null,
    timeEnded: null,
    survey,
    dyingCount: 0,
    done: false,
    cancelled: false,
    toData,
    reset,
    end,
    screenToLevel,
    levelToScreen,
    screenToGrid,
    gridToScreen
  };
  reset();
  function reset() {
    player.reset();
    grid.reset();
    level.timeStarted = new Date();
    level.done = false;
    level.cancelled = false;
  }
  function end() {
    level.timeEnded = new Date();
    level.done = true;
  }
  function screenToLevel({
    x,
    y
  }) {
    return {
      x: x + offset.x,
      y: y + offset.y
    };
  }
  function levelToScreen({
    x,
    y
  }) {
    return {
      x: x - offset.x,
      y: y - offset.y
    };
  }
  function screenToGrid({
    x,
    y
  }) {
    return grid.levelToGrid(screenToLevel({
      x,
      y
    }));
  }
  function gridToScreen({
    x,
    y
  }) {
    return levelToScreen(grid.gridToLevel({
      x,
      y
    }));
  }
  function toData() {
    return {
      name,
      id,
      columns,
      rows,
      tileSize,
      player: player.toData(),
      tiles: grid.toData()
    };
  }
  return level;
}