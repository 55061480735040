import Jump from "./jump";
import MoveLeft from "./moveLeft";
import MoveRight from "./moveRight";
import Duck from "./duck";
import Reset from "./reset";
import Cancel from "./cancel";
const all = {
  Jump,
  MoveLeft,
  MoveRight,
  Duck,
  Cancel
};
export default function Controller(scene, level) {
  const ctrls = {};
  Object.keys(all).forEach(key => {
    const ctrl = all[key];
    ctrls[key] = ctrl(scene, level);
  });
  return ctrls;
}
export { Jump, MoveLeft, MoveRight, Duck };