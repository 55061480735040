import View from "core/view";
export const Event = {
  StartGame: "game",
  StartEditor: "editor",
  ChooseLevel: "chooseLevel"
};
export default function StartView(element) {
  const view = View({
    element,
    events: Event
  });
  const editorInfo = element.querySelector("#editor-info");
  const showEditorInfo = () => editorInfo.classList.remove("hidden");
  const chooserInfo = element.querySelector("#chooser-info");
  const showChooserInfo = () => chooserInfo.classList.remove("hidden");
  function onKeyDown(callback) {
    element.addEventListener("keydown", callback);
  }
  return Object.assign(view, {
    onKeyDown,
    showEditorInfo,
    showChooserInfo
  });
}