import TilesSheet from "./tiles_spritesheet.json";
import ItemsSheet from "./items_spritesheet.json";
import Player1Sheet from "./p1_spritesheet.json";
import Player2Sheet from "./p2_spritesheet.json";
import Player3Sheet from "./p3_spritesheet.json";
export default {
  tiles: TilesSheet,
  items: ItemsSheet,
  player1: Player1Sheet,
  player2: Player2Sheet,
  player3: Player3Sheet
};