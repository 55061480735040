export const LogLevel = {
  Debug: "debug",
  Info: "info",
  Warning: "warning",
  Error: "error"
};
const LevelRank = Object.values(LogLevel);
const rankOf = level => LevelRank.indexOf(level);
export default function Log(name) {
  const logger = {};
  LevelRank.forEach(rank => {
    logger[rank] = makeLogger(name, rank);
  });
  return logger;
}
Log.level = LogLevel.Info;
function makeLogger(name, level) {
  return (...text) => log(name, level, ...text);
}
function log(name, level, ...text) {
  if (rankOf(level) < rankOf(Log.level)) return;
  const msg = text.join(" ");
  const line = `[${name}] [${level}] ${msg}`;
  console.log(line);
  if (level == LogLevel.Error) console.error(text);
}