import R from "core/resources";
import Settings from "settings";
const walkDivider = 2;
const debugCfg = Settings.debug.player;
export default function PlayerRenderer(player) {
  const standSprite = R.gfx.player.getSprite("p3_front").sprite;
  const jumpSprite = R.gfx.player.getSprite("p3_jump").sprite;
  const duckSprite = R.gfx.player.getSprite("p3_duck").sprite;
  const walkSprites = makeWalkAnimation();
  const isReversing = () => player.velocity.x < 0;
  const isResting = () => player.velocity.x == 0;
  function determineSprite() {
    const {
      landed,
      ducking
    } = player;
    const resting = isResting();
    if (landed) {
      if (resting) return ducking ? duckSprite : standSprite;
      return walkSprites[walkIndex];
    }
    return resting ? standSprite : jumpSprite;
  }
  let walkIndex = 0;
  function progressWalkAnimation(p) {
    if (p.frameCount % walkDivider == 0) {
      walkIndex = (++walkIndex + walkSprites.length) % walkSprites.length;
    }
  }
  return (p, scene) => {
    const {
      position: {
        x,
        y
      }
    } = player;
    const sprite = determineSprite();
    const reversing = isReversing();
    const spriteOffsetX = (player.size.width - sprite.width) / 2;
    const spriteOFfsetY = player.size.height - sprite.height;
    p.push();
    p.translate(x, y);
    p.translate(spriteOffsetX, spriteOFfsetY);
    if (reversing) {
      p.translate(sprite.width, 0);
      p.scale(-1, 1);
    }
    p.tint(255, player.opacity);
    p.image(sprite, 0, 0);
    p.pop();
    if (debugCfg.renderPlayerBox) renderPlayerBox(p, player);
    if (debugCfg.renderBoundingBox) renderBoundingBox(p, player);
    progressWalkAnimation(p);
  };
}
function renderBoundingBox(p, player) {
  if (!("boundingBox" in player)) return;
  const {
    boundingBox: {
      x1,
      y1,
      x2,
      y2
    }
  } = player;
  p.rectMode(p.CORNERS);
  p.stroke([255, 0, 255]);
  p.noFill();
  p.rect(x1, y1, x2, y2);
}
function renderSpriteBox(p, player, sprite) {
  const {
    position: {
      x,
      y
    }
  } = player;
  const {
    width,
    height
  } = sprite;
  p.rectMode(p.CORNER);
  p.stroke([255, 255, 0]);
  p.noFill();
  p.rect(x, y, width, height);
}
function renderPlayerBox(p, player) {
  const {
    position: {
      x,
      y
    },
    size: {
      width,
      height
    }
  } = player;
  p.rectMode(p.CORNER);
  p.stroke([0, 255, 255]);
  p.noFill();
  p.rect(x, y, width, height);
}
function makeWalkAnimation() {
  const sprites = [];
  for (let i = 1; i < 12; i++) {
    const spriteName = `p3_walk` + `${i}`.padStart(2, '0');
    sprites.push(R.gfx.player.getSprite(spriteName).sprite);
  }
  return sprites;
}