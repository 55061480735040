import { arrayToObject, prefix } from "core/util";
export default function Events(eventsArray) {
  const eventHandlers = createEventHandlers(eventsArray);
  const onMethods = createOnMethods(on, eventsArray);
  const offMethods = createOffMethods(off, eventsArray);
  const fireMethods = createFireMethods(fire, eventsArray);
  const events = {
    canFire,
    fire,
    ...onMethods,
    ...offMethods,
    ...fireMethods,
    on,
    off
  };
  function fire(event, ...args) {
    if (!(event in eventHandlers)) throw new Error(`Unsupported event! (${event})`);
    for (const handler of eventHandlers[event]) {
      if (handler(...args)) return true;
    }
    ;
  }
  function canFire(event) {
    if (!(event in eventHandlers)) return false;
    return eventHandlers[event].size != 0;
  }
  function on(event, handler) {
    if (!(event in eventHandlers)) throw new Error(`Unsupported event! (${event})`);
    eventHandlers[event].add(handler);
  }
  function off(event, handler) {
    if (!(event in eventHandlers)) throw new Error(`Unsupported event! (${event})`);
    eventHandlers[event].delete(handler);
  }
  return events;
}
function createEventHandlers(events) {
  return arrayToObject(events, () => new Set());
}
function createOnMethods(on, events) {
  return arrayToObject(events, (cur, obj) => {
    const methodName = prefix("on", cur);
    obj[methodName] = cb => on(cur, cb);
  });
}
function createOffMethods(off, events) {
  return arrayToObject(events, (cur, obj) => {
    const methodName = prefix("off", cur);
    obj[methodName] = cb => off(cur, cb);
  });
}
function createFireMethods(fire, events) {
  return arrayToObject(events, (cur, obj) => {
    const methodName = prefix("fire", cur);
    obj[methodName] = (...args) => fire(cur, ...args);
  });
}