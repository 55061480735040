export default function ButtonDownController(view) {
  return ({
    index
  }) => {
    console.log("ButtonDownCtrl", index);
    switch (index) {
      case 0:
      case 1:
      case 2:
      case 3:
        view.resolve("start");
      default: // any other key
    }
  };
}