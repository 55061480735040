import QuestionHandler from "../question-handler";
export default function QuestionHitPhysics(level, physics) {
  const {
    player,
    grid
  } = level;
  const {
    position,
    size
  } = player;
  const {
    width,
    height
  } = size;
  const pos = {
    x: position.x + width / 2,
    y: position.y + height - 5
  };
  const gridPos = grid.levelToGrid(pos);
  const {
    x,
    y
  } = gridPos;
  const item = grid.getItem(x, y);
  if (!item) return;
  grid.setItem(x, y, null);
  QuestionHandler(level, physics);
}