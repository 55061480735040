import LevelRenderer from "../core/level-renderer";
export default function MainRenderer(main) {
  const {
    level
  } = main;
  const levelRenderer = LevelRenderer(level);
  return (p, scene) => {
    p.background(200, 225, 255);
    levelRenderer(p, scene);
  };
}