import R from "core/resources";
const typeToColor = {
  default: [255, 255, 255],
  error: [255, 32, 32]
};
export default function Toast() {
  const font = R.fonts.sunnySpells;
  const textSize = 32;
  const radius = 20;
  const timeToShow = 120;
  const fadeSpeed = 4;
  let textToShow = null;
  function render(p) {
    if (!textToShow) return;
    if (!("timeUntilFade" in textToShow)) textToShow.timeUntilFade = timeToShow;
    textToShow.timeUntilFade--;
    if (textToShow.timeUntilFade < 0) {
      if (!("fade" in textToShow)) textToShow.fade = 128;
      textToShow.fade -= fadeSpeed;
      if (textToShow.fade < 0) {
        textToShow = null;
        return;
      }
    }
    const {
      text,
      type
    } = textToShow;
    const bounds = font.textBounds(text, 220, 220, textSize);
    const width = bounds.w + 2 * radius;
    const height = bounds.h + 2 * radius;
    const midX = p.width / 2,
      midY = p.height / 2;
    const alpha = "fade" in textToShow ? textToShow.fade : 128;
    p.fill([0, 0, 0, alpha]);
    p.noStroke();
    p.rectMode(p.CENTER);
    p.rect(midX, midY, width, height, radius);
    p.textSize(textSize);
    p.textFont(font);
    p.textAlign(p.CENTER, p.CENTER);
    const color = typeToColor[type];
    p.fill([...color, 2 * alpha]);
    p.text(text, midX, midY);
  }
  function show(text, type = "default", timeUntilFade = timeToShow) {
    textToShow = {
      text,
      type,
      timeUntilFade
    };
  }
  return {
    render,
    show
  };
}