import Component, { Align } from "core/gui/component";
import Icon from "./icon";
export const Direction = {
  Row: "row",
  Column: "column"
};
export default function ToolBar(icons, direction = Direction.Row, padding = 8) {
  const children = icons.map(Icon);
  const size = determineSize(children, direction, padding);
  const component = new Component({
    name: "ToolBar",
    alignment: {
      x: Align.Right,
      y: Align.Bottom
    },
    size
  });
  children.forEach(child => component.add(child));
  alignItems();
  component.renderComponent = p => {
    const {
      width,
      height
    } = size;
    p.fill([0, 0, 0, 64]);
    p.noStroke();
    p.rect(0, 0, width, height);
  };
  function alignItems() {
    const isRow = direction == Direction.Row;
    let x = padding,
      y = padding;
    children.forEach(child => {
      child.position.x = x;
      child.position.y = y;
      if (isRow) x += child.size.width + padding;else y += child.size.height + padding;
    });
  }
  return component;
}
function determineSize(children, direction, padding) {
  const isRow = direction == Direction.Row;
  let width = isRow ? padding : 0;
  let height = isRow ? 0 : padding;
  children.forEach(({
    size
  }) => {
    if (isRow) {
      width += size.width + padding;
      height = Math.max(height, size.height);
    } else {
      width = Math.max(width, size.width);
      height += size.height + padding;
    }
  });
  if (isRow) height += 2 * padding;else width += 2 * padding;
  return {
    width,
    height
  };
}