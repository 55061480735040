import Settings from "settings";
const {
  editor,
  levelChooser
} = Settings;
export default function KeyDownController(view) {
  if (editor.enabled) view.showEditorInfo();
  if (levelChooser.enabled) view.showChooserInfo();
  return evt => {
    const {
      keyCode
    } = evt;
    switch (keyCode) {
      case 81:
        // Q
        if (levelChooser.enabled) view.resolve("quit");
        break;
      case 69:
        // E
        if (editor.enabled) view.resolve("edit");
        break;
      default:
        // any other key
        view.resolve("start");
    }
  };
}