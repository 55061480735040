import Deferred from "../../core/deferred";
export default function ResizeDialog() {
  const dialog = document.querySelector("dialog#editor-dialog-resizer");
  const columnInput = dialog.querySelector("input[name=columns");
  const rowInput = dialog.querySelector("input[name=rows");
  const okButton = dialog.querySelector("button.ok");
  let deferred = null;
  let lastSize = null;
  dialog.addEventListener("close", () => {
    okButton.removeEventListener("click", onOkClick);
  });
  function open(levelData) {
    okButton.addEventListener("click", onOkClick);
    console.log("ResizeDialog.open");
    deferred = new Deferred();
    const {
      columns,
      rows
    } = levelData;
    lastSize = {
      columns,
      rows
    };
    columnInput.value = columns;
    rowInput.value = rows;
    dialog.showModal();
    return deferred.promise;
  }
  function onOkClick(evt) {
    console.log("ResizeDialog.ok");
    evt.preventDefault();
    const columns = parseInt(columnInput.value);
    const rows = parseInt(rowInput.value);
    const shrink = columns < lastSize.columns || rows < lastSize.rows;
    if (shrink) {
      if (!confirm("Weniger Spalten oder Zeilen! Wirklich verkleinern?")) return;
    }
    const size = {
      columns,
      rows
    };
    dialog.close(size);
    deferred.resolve(size);
    deferred = null;
  }
  return {
    open
  };
}