import Gamepad, { Event } from "core/gamepad";
import Element from "app/element";
export default function SurveyHandler(level, physics) {
  const {
    survey
  } = level;

  //if (!survey.hasQuestion()) return;

  const overlay = Element("#game-question");
  const questionText = Element("#game-question-text", overlay);
  const questionAnswers = Element("#game-question-answers", overlay);
  const help = Element("#game-question-help", overlay);
  const assistance = Element("#game-question-assistance", overlay);
  const start = Element("#game-question-start", overlay);
  questionText.setVisible(true);
  questionAnswers.setVisible(true);
  assistance.setVisible(false);
  help.setVisible(true);
  start.setVisible(false);
  physics.setEnabled(false);
  overlay.setVisible(true);
  let selectedAnswer = null; //Element(".selected", questionAnswers);
  let surveyDone = false;
  let showAssistance = false;
  nextQuestion();
  function nextQuestion() {
    const text = survey.getQuestion();
    const answers = survey.getAnswers();
    questionText.innerHTML = text;
    questionAnswers.innerHTML = "";
    answers.forEach(answer => questionAnswers.appendChild(makeAnswer(answer)));
  }
  Gamepad.on(Event.ButtonDown, onButtonPressed);
  Gamepad.on(Event.Move, onAxisMove);
  document.addEventListener("keydown", onKeyDown);
  function onKeyDown(evt) {
    if (evt.repeat) return;
    if (surveyDone) {
      end();
      return;
    }
    selectedAnswer && selectedAnswer.setSelected(false);
    switch (evt.keyCode) {
      case 13:
        // RETURN
        submitAnswer();
        break;
      case 37:
      case 65:
        // Left
        moveLeft();
        break;
      case 39:
      case 68:
        // Right
        moveRight();
        break;
      case 27:
        // Esc
        cancel();
        break;
    }
    selectedAnswer && selectedAnswer.setSelected(true);
  }
  function onButtonPressed({
    index
  }) {
    if (surveyDone) {
      end();
      return;
    }
    selectedAnswer && selectedAnswer.setSelected(false);
    switch (index) {
      case 14:
        // left
        moveLeft();
        break;
      case 15:
        // right
        moveRight();
        break;
      case 0:
      case 1:
      case 2:
      case 3:
        submitAnswer();
        break;
    }
    selectedAnswer && selectedAnswer.setSelected(true);
  }
  function onAxisMove({
    index,
    value
  }) {
    if (index != 0) return;
    selectedAnswer && selectedAnswer.setSelected(false);
    if (value < -0.5) moveLeft();else if (value > 0.5) moveRight();
    selectedAnswer && selectedAnswer.setSelected(true);
  }
  function moveLeft() {
    if (selectedAnswer && selectedAnswer.previousElementSibling) selectedAnswer = Element(selectedAnswer.previousElementSibling);else selectedAnswer = Element(questionAnswers.firstElementChild);
  }
  function moveRight() {
    if (selectedAnswer && selectedAnswer.nextElementSibling) selectedAnswer = Element(selectedAnswer.nextElementSibling);else selectedAnswer = Element(questionAnswers.lastElementChild);
  }
  function submitAnswer() {
    if (!selectedAnswer) return;
    const answer = selectedAnswer.innerHTML;
    survey.setAnswer(answer);
    showAssistance = showAssistance || survey.hasAssistance() && survey.needsAssistance(answer);
    if (showAssistance) console.log(survey.getQuestion());
    if (selectedAnswer) {
      selectedAnswer.setSelected(false);
      selectedAnswer = null;
    }
    const nextExists = survey.gotoNextQuestion();
    if (nextExists) {
      nextQuestion();
      return;
    }
    done();
  }
  function done() {
    questionText.setVisible(false);
    questionAnswers.setVisible(false);
    assistance.setVisible(showAssistance);
    help.setVisible(false);
    start.setVisible(true);
    surveyDone = true;
  }
  function cancel() {
    end();
  }
  function end() {
    document.removeEventListener("keydown", onKeyDown);
    Gamepad.off(Event.ButtonDown, onButtonPressed);
    overlay.setVisible(false);
    physics.setEnabled(true);
  }
}
function makeAnswer(answer) {
  const element = document.createElement("div");
  element.classList.add("game-question-answer");
  element.innerHTML = answer;
  return element;
}