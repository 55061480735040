import R from "core/resources";
import Scene from "core/scene/scene";
import GridRenderer from "core/grid-renderer";
import Deferred from "core/deferred";
import TileState from "./tiles";
import ItemState from "./items";
const columns = 8;
const tileSize = 70;
const STATE_TILE = "tiles";
const STATE_ITEM = "items";
const HOVERED_COLOR = [0, 255, 255];
const SELECTED_COLOR = [255, 0, 0];
export default function SpriteChooser() {
  const scene = new Scene("spritechooser-canvas", 300, 500);
  const container = document.querySelector("#editor-modal");
  container.querySelectorAll("button").forEach(btn => preventFocus(btn));
  scene.render.on(render);
  const state = StateMachine(() => {
    scene.resize(state.scene).then(() => scrollToSelected());
  });
  let hoveredTileIndex = null;
  let selectedTileIndex = null;
  scene.input.onMouseMoved(p => {
    hoveredTileIndex = getIndexByMouse(p);
  });
  function getIndexByMouse(p) {
    const {
      grid
    } = state;
    const {
      x,
      y
    } = grid.levelToGrid({
      x: p.mouseX,
      y: p.mouseY
    });
    return grid.gridToIndex(x, y);
  }
  scene.input.onMousePressed(p => {
    submit({
      type: state.getState(),
      index: getIndexByMouse(p)
    });
  });
  scene.input.onKeyPressed(p => {
    switch (p.keyCode) {
      case 81:
        // Q
        cancel();
        break;
    }
  });
  function render(p) {
    const {
      grid
    } = state;
    const gridRenderer = GridRenderer(grid, true);
    p.clear();
    gridRenderer(p, scene);
    if (selectedTileIndex != null) renderFrame(p, selectedTileIndex, SELECTED_COLOR);
    if (hoveredTileIndex != null) renderFrame(p, hoveredTileIndex, HOVERED_COLOR, true);
  }
  function renderFrame(p, index, color, label = false) {
    const {
      grid
    } = state;
    const gridPos = grid.indexToGrid(index);
    const {
      x,
      y
    } = grid.gridToLevel(gridPos);
    const tile = grid.getTile(gridPos.x, gridPos.y);
    if (tile == null) return;
    p.stroke(color);
    p.strokeWeight(3);
    p.noFill();
    p.rect(x, y, tileSize, tileSize);
    if (label) {
      const font = R.fonts.steelfish;
      p.textSize(25);
      p.textAlign(p.CENTER, p.BOTTOM);
      p.textFont(font);
      const spriteName = tile.name;
      const textX = x + tileSize / 2,
        textY = y + tileSize;
      const bounds = font.textBounds(spriteName, textX, textY, p.textSize());
      const rectX = textX - bounds.w / 2 - 4;
      const rectY = y + tileSize - bounds.h - 4;
      p.noStroke();
      p.fill([255, 255, 255, 128]);
      p.rect(rectX, rectY, bounds.w + 8, bounds.h + 8);
      p.fill(0);
      p.text(spriteName, textX, textY);
    }
  }
  let deferred = null;
  function open(value) {
    if (value) {
      const {
        type,
        index
      } = value;
      state.setState(type);
      selectedTileIndex = index;
    } else {
      state.setState(STATE_TILE);
      selectedTileIndex = 0;
    }
    if (deferred == null) {
      deferred = new Deferred();
      container.classList.remove("hidden");
      scene.start();
    }
    if (value) scrollToSelected();
    return deferred.promise;
  }
  function scrollToSelected() {
    if (selectedTileIndex == null) return;
    const {
      grid
    } = state;
    const gridPos = grid.indexToGrid(selectedTileIndex);
    const {
      x,
      y
    } = grid.gridToLevel(gridPos);
    scene.parent.parentElement.scrollTo(x, y);
  }
  function cancel() {
    deferred.reject();
    close();
  }
  function submit(value) {
    console.log("submitted", value);
    deferred.resolve(value);
    close();
  }
  function close() {
    scene.stop();
    container.classList.add("hidden");
    deferred = null;
  }
  return {
    open
  };
}
function StateMachine(cb = () => {}) {
  const states = {
    tiles: TileState(),
    items: ItemState()
  };
  let state = null;
  const hasState = toTest => state == toTest;
  const getState = () => state;
  const buttonsArray = Array.from(document.querySelectorAll("#spritechooser-buttons button"));
  const buttons = buttonsArray.reduce((obj, btn) => {
    const stateToSet = btn.dataset.state;
    obj[stateToSet] = btn;
    btn.addEventListener("click", () => setState(stateToSet));
    return obj;
  }, {});
  const machine = {
    hasState,
    getState,
    setState
  };
  function setState(newState) {
    state = newState;
    Object.values(buttons).forEach(btn => btn.classList.remove("active"));
    buttons[state].classList.add("active");
    const obj = states[state];
    Object.assign(machine, obj);
    cb(machine);
  }
  return machine;
}
function preventFocus(element) {
  element.addEventListener("focus", event => {
    if (event.relatedTarget) {
      event.relatedTarget.focus();
    } else {
      event.currentTarget.blur();
    }
  });
}