import Physics from "./physics";
import { PlayerControl } from "game/player";
import HUD from "./hud";
import CollisionPhysics from "./physics/collision";
import KinematicsPhysics from "./physics/kinematics";
import QuestionHitPhysics from "./physics/question-hit";
import MainRenderer from "app/main-renderer";
export default function Game(scene, level, onCancel, onDone) {
  const physics = Physics(level);
  physics.addPlugin(KinematicsPhysics);
  physics.addPlugin(CollisionPhysics);
  physics.addPlugin(QuestionHitPhysics);
  const hud = HUD(level);
  const control = PlayerControl(scene, level);
  const renderer = MainRenderer({
    level
  });
  function start() {
    control.setEnabled(true);
    scene.render.on(renderer);
    scene.render.on(physics.update);
    scene.render.on(hud.render);
    scene.render.on(exitCheck);
    scene.gui.add(hud);
  }
  function stop() {
    control.setEnabled(false);
    scene.render.off(renderer);
    scene.render.off(physics.update);
    scene.render.off(hud.render);
    scene.render.off(exitCheck);
    scene.gui.remove(hud);
  }
  function exitCheck() {
    if (level.done) {
      stop();
      onDone();
    } else if (level.cancelled) {
      stop();
      onCancel();
    }
  }
  return {
    start
  };
}