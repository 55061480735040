import R from "core/resources";
export default function Grid({
  columns,
  rows,
  tileSize,
  tiles: tilesData = null
}) {
  const width = columns * tileSize;
  const height = rows * tileSize;
  let tiles = null;
  let dirty = false;
  let changed = false;
  reset();
  function reset() {
    if (tilesData) {
      tiles = resolveTilesByName(tilesData);
    } else {
      tiles = new Array(columns).fill(0).map(() => new Array(rows).fill(0).map(() => null));
    }
  }
  function commit() {
    tilesData = toData();
    if (changed) {
      markDirty();
    }
  }
  function isDirty() {
    return dirty;
  }
  ;
  function markDirty() {
    dirty = true;
  }
  ;
  function markClean() {
    dirty = false;
    changed = false;
  }
  ;
  function levelToGrid(screenPos) {
    return {
      x: Math.floor(screenPos.x / tileSize),
      y: Math.floor(screenPos.y / tileSize)
    };
  }
  function gridToLevel(gridPos) {
    return {
      x: gridPos.x * tileSize,
      y: gridPos.y * tileSize
    };
  }
  function gridToIndex(x, y) {
    return x + y * columns;
  }
  function indexToGrid(index) {
    return {
      x: index % columns,
      y: Math.floor(index / columns)
    };
  }
  ;
  function setTile(x, y, sprite) {
    if (x < 0 || x >= tiles.length || y < 0 || y >= tiles[x].length) return;
    let current = tiles[x][y];
    if (!current?.tile && !sprite) return;
    if (!sprite) {
      delete current.tile;
      changed = true;
      return;
    }
    if (!current) current = {};
    if (current.tile == sprite) return;
    current.tile = sprite;
    tiles[x][y] = current;
    changed = true;
  }
  function setItem(x, y, sprite) {
    if (x < 0 || x >= tiles.length || y < 0 || y >= tiles[x].length) return;
    let current = tiles[x][y];
    if (!current?.item && !sprite) return;
    if (!sprite && current) {
      delete current.item;
      changed = true;
      return;
    }
    if (!current) current = {};
    if (current.item == sprite) ;
    current.item = sprite;
    tiles[x][y] = current;
    changed = true;
  }
  function getTile(x, y) {
    return tiles[x][y]?.tile;
  }
  function getItem(x, y) {
    return tiles[x][y]?.item;
  }
  function toData() {
    return tiles.map(col => col.map(tile => {
      if (!tile) return;
      const data = {};
      if (tile.tile) data.tile = tile.tile.name;
      if (tile.item) data.item = tile.item.name;
      return data;
    }));
  }
  return {
    width,
    height,
    columns,
    rows,
    tileSize,
    tiles,
    reset,
    commit,
    toData,
    setTile,
    getTile,
    setItem,
    getItem,
    levelToGrid,
    gridToLevel,
    indexToGrid,
    gridToIndex,
    isDirty,
    markDirty,
    markClean
  };
}
function resolveTilesByName(tileNames) {
  return tileNames.map(col => col.map(tileData => {
    if (!tileData) return null;
    const tile = {};
    if (tileData.tile) tile.tile = R.gfx.tiles.getSprite(tileData.tile);
    if (tileData.item) tile.item = R.gfx.items.getSprite(tileData.item);
    return tile;
  }));
}